import React from 'react'
import PropTypes from 'prop-types'
import { HiTrash } from 'react-icons/hi'
import ActionButton from '/src/ui/core/buttons/action_button'
import '/src/static/css/core/inputs/input_multiple_entries.css'

export default function EntryItem({ label, handleDelete }) {
  return (
    <div className='entry-item' data-testid='entry-item'>
      <div className='entry-item__list-label'>{label}</div>
      <div className='entry-item__section-delete'>
        <ActionButton
          title=''
          className='button-action-discard'
          icon={<HiTrash />}
          onClick={handleDelete}
        />
      </div>
    </div>
  )
}

EntryItem.propTypes = {
  label: PropTypes.string.isRequired,
  handleDelete: PropTypes.func.isRequired
}

import initialValue from '/src/utils/store/initial_store.json'
import { getEnvironment, setEnvironment } from '/src/utils/store/environment'
import { getUser, setUser } from '/src/utils/store/user'
import { getLanguage, setLanguage } from '/src/utils/store/language'
import { getGridSettings, setGridSettings } from '/src/utils/store/grid_settings'
import { getTemplateFilters, setTemplateFilters } from '/src/utils/store/template_filters'
import { getBatchEntities, setBatchEntities } from '/src/utils/store/batch_entities'
import { getLastSync, setLastSync } from '/src/utils/store/last_sync'
import { getSubprojectFilters, setSubprojectFilters } from '/src/utils/store/subproject_filters'
import { getSidePanelHidden, setSidePanelHidden } from '/src/utils/store/side_panel_hidden'
import { getScaffoldingStatusFilter, setScaffoldingStatusFilter } from '/src/utils/store/scaffolding_status_filter'
import { getEstimateStatusFilter, setEstimateStatusFilter } from '/src/utils/store/estimates_status_filter'
import { getScaffoldingTypeFilter, setScaffoldingTypeFilter } from '/src/utils/store/scaffolding_type_filter'

function manipulateInitialValue() {
  let initState = {}
  initState = {
    ...initialValue,
    ...getEnvironment(),
    ...getUser(),
    ...getLanguage(),
    ...getGridSettings(),
    ...getBatchEntities(),
    ...getLastSync(),
    ...getTemplateFilters(),
    ...getSubprojectFilters(),
    ...getSidePanelHidden(),
    ...getScaffoldingStatusFilter(),
    ...getScaffoldingTypeFilter(),
    ...getEstimateStatusFilter()

  }
  return initState
}

export default function useInitialState() {
  const initState = manipulateInitialValue()
  const config = {
    listener: (state) => {
      setEnvironment(state)
      setUser(state)
      setLanguage(state)
      setBatchEntities(state)
      setLastSync(state)
      setGridSettings(state)
      setTemplateFilters(state)
      setSubprojectFilters(state)
      setSidePanelHidden(state)
      setScaffoldingStatusFilter(state)
      setScaffoldingTypeFilter(state)
      setEstimateStatusFilter(state)
    }
  }

  return [initState, config]
}

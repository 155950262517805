import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import ColumnInput from '/src/ui/core/inputs/column_input'
import I18n from '/src/utils/translations'
import { isBlank } from '/src/utils/boolean_refinements'
import BatchEditionDeleteButton from '/src/ui/core/popups/columns_batch_assigner/batch_edition_delete_button'
import { isDatasheetFilter } from '/src/models/concerns/eav_column'
import InputDrop from '/src/ui/core/inputs/input_drop'
import ThreeDotsLoader from '/src/ui/core/loaders/three_dots_loader'

export default function ColumnsBatchAssignerForm({
  columns,
  selectedColumn,
  selectedColumnValue,
  onSelectColumn,
  onValueEdition,
  onSaveAction,
  onDeleteAction,
  datasheetFiltersByTag
}) {
  const inputDataItem = selectedColumn ? { [selectedColumn.description]: selectedColumnValue } : {}

  const onColumnChange = (_id, _columnId, column) => {
    onSelectColumn(column)
    onValueEdition()
  }

  const onValueChange = useCallback((id, value) => {
    const columnType = selectedColumn?.column_type?.description
    const formattedValue = ['multiple_drop', 'multiple_datasheet_filter'].includes(columnType)
      ? JSON.stringify(value)
      : value

    onValueEdition((prevValue) => {
      if (selectedColumn && isDatasheetFilter(selectedColumn)) {
        if (typeof prevValue !== 'object') return { [id]: formattedValue }

        return {
          ...prevValue,
          [id]: formattedValue
        }
      }

      return formattedValue
    })
  }, [selectedColumn, onValueEdition])

  const inputColumn = useMemo(() => {
    if (selectedColumn && isDatasheetFilter(selectedColumn)) {
      const { metadata } = selectedColumn

      const column = {
        ...datasheetFiltersByTag[metadata],
        filters: datasheetFiltersByTag[metadata].filters.map((filter) => ({
          ...filter,
          value: selectedColumnValue?.[filter.description]
        }))
      }

      return { ...column, onChange: onValueChange }
    }

    return selectedColumn
  }, [selectedColumn, selectedColumnValue, datasheetFiltersByTag, onValueChange])

  const isSaveButtonDisabled = (column, values) => {
    if (column && isDatasheetFilter(column) && values) {
      return Object.values(values).every((value) => value === undefined)
    }

    return isBlank(selectedColumn) || isBlank(selectedColumnValue)
  }

  if (!columns) return <ThreeDotsLoader />

  return (
    <React.Fragment>
      <div className="column-assigner-editing">
        <div className="column-container">
          <span className="column-name">{I18n.t('dialogs.columns_batch_assigner.column')}</span>
          <InputDrop
            inputProps={{
              id: 'selected-column-combobox',
              textField: 'title',
              options: columns,
              onChange: onColumnChange,
              value: selectedColumn?.id
            }}
          />
        </div>
        <div className="value-container">
          <span className="column-value">{I18n.t('dialogs.columns_batch_assigner.new_value')}</span>
          {selectedColumn ? (
            <ColumnInput
              key={selectedColumn?.description}
              id="new-value-container"
              column={inputColumn}
              onChange={onValueChange}
              dataItem={inputDataItem}
            />
          ) : (
            <div
              className="empty-value-container"
              id="empty-new-value"
              data-testid="empty-new-value-input"
            >
              {' '}
            </div>
          )}
        </div>
        <div className="buttons-container">
          <BatchEditionDeleteButton column={selectedColumn} onDeleteAction={onDeleteAction} />
          <button
            className="done-button"
            disabled={isSaveButtonDisabled(selectedColumn, selectedColumnValue)}
            onClick={() => onSaveAction(selectedColumn, selectedColumnValue)}
            type="button"
          >
            {I18n.t('dialogs.columns_batch_assigner.done')}
          </button>
        </div>
      </div>
    </React.Fragment>
  )
}

ColumnsBatchAssignerForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.arrayOf(PropTypes.object),
  selectedColumn: PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string,
    column_type: PropTypes.shape({
      description: PropTypes.string
    }),
    metadata: PropTypes.string
  }),
  selectedColumnValue: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
    PropTypes.object
  ]),
  onSelectColumn: PropTypes.func.isRequired,
  onValueEdition: PropTypes.func.isRequired,
  onSaveAction: PropTypes.func.isRequired,
  onDeleteAction: PropTypes.func,
  datasheetFiltersByTag: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string,
      filters: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number
      }))
    })
  )
}

ColumnsBatchAssignerForm.defaultProps = {
  columns: undefined,
  selectedColumn: undefined,
  selectedColumnValue: undefined,
  onDeleteAction: undefined,
  datasheetFiltersByTag: undefined
}

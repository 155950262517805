import React, { useState } from 'react'
import I18n from '/src/utils/translations'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import ColumnsBatchAssigner from '/src/ui/core/popups/columns_batch_assigner'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import PropTypes from 'prop-types'
import { ReactComponent as DynamicForm } from '/public/static/svg/dynamic-form'
import '/src/static/css/core/icons/popup_icon.css'

export default function BatchAssignerIcon({ model, templateId }) {
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [selectedRecords, setSelectedRecords] = useState([])
  const modelName = model?.paramName
  const modelRoute = model?.route

  useBus(
    BusEvents.SELECTED_ROWS,
    ({ triggeredModelName, payload }) => {
      if (triggeredModelName === modelName || triggeredModelName === 'ALL_MODELS') {
        setSelectedRecords(payload)
      }
    },
    [modelName, setSelectedRecords]
  )

  const handleSuccessfulBatchUpdate = () => {
    setIsPopupOpen(false)
    dispatch(BusEvents.RELOAD_GRID)
    setSelectedRecords([])
  }

  const shouldHideIcon = selectedRecords
    .some((record) => record.progress_service_status_id === 3 || record.progress_status_id === 2)

  if (selectedRecords.length === 0 || shouldHideIcon) return null

  const buttonClass = 'batch-assigner-icon__button open-icon-popup btn-icon grid-header-icon'

  return (
    <div className="batch-assigner-icon">
      <CustomTooltip position="bottom" parentTitle>
        <button
          className={buttonClass + (isPopupOpen ? ' active-button' : '')}
          title={I18n.t('dialogs.columns_batch_assigner.header')}
          onClick={() => setIsPopupOpen(true)}
          type='button'
        >
          <DynamicForm />
        </button>
      </CustomTooltip>

      {isPopupOpen && (
        <ColumnsBatchAssigner
          isOpen={isPopupOpen}
          eavTemplateId={templateId}
          onClose={() => setIsPopupOpen(false)}
          selectedRecords={selectedRecords}
          modelRoute={modelRoute}
          onSuccessfulUpdate={handleSuccessfulBatchUpdate}
        />
      )}
    </div>
  )
}

BatchAssignerIcon.propTypes = {
  templateId: PropTypes.number,
  model: PropTypes.oneOfType([PropTypes.object])
}

BatchAssignerIcon.defaultProps = {
  templateId: null,
  model: null
}

import React, { useEffect, useMemo, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import { useLocation } from 'react-router-dom'
import { useQueryParam, NumberParam } from 'use-query-params'
import Tabs from '/src/ui/core/layouts/tabs'

export default function TemplateTabs({ tabs, onTemplateChange }) {
  const [urlTabId, setUrlTabId] = useQueryParam('eav_template_id', NumberParam)
  const { pathname } = useLocation()
  const initialUrl = useRef(pathname)
  const [interactiveMode] = useStore('interactive_mode')

  const onTabChange = useCallback((tab) => {
    setUrlTabId(tab?.id)
    if (tab && onTemplateChange) onTemplateChange?.(tab)
  }, [setUrlTabId, onTemplateChange])

  // The onTemplateChange function is invoked with the first tab, or url tb, 
  // to inform the parent component of the initial active tab.
  useEffect(() => {
    if (urlTabId && tabs?.some((tab) => tab.id === urlTabId)) {
      const activeTab = tabs.find((tab) => tab.id === urlTabId)
      onTemplateChange?.(activeTab)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (initialUrl.current !== pathname) {
      initialUrl.current = pathname
    } else if (!urlTabId || !tabs.some((tab) => tab.id === urlTabId)) {
      onTabChange(tabs[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs, onTemplateChange, urlTabId, pathname])

  return (
    <Tabs
      tabsList={tabs}
      onTabChange={onTabChange}
      activeTabId={urlTabId ?? tabs?.[0]?.id}
      filterTabs={interactiveMode && interactiveMode.status}
    />
  )
}

TemplateTabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
  onTemplateChange: PropTypes.func
}

TemplateTabs.defaultProps = {
  onTemplateChange: () => { }
}

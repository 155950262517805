import React from 'react'
import PropTypes from 'prop-types'
import useSectionBlock from '/src/ui/core/blocks/section_block_hook'
import BlocksSidePanel from '/src/ui/core/grid/side_panel/blocks_side_panel'
import SidePanelTabs from '/src/ui/core/grid/side_panel/side_panel_tabs'
import { getGalleryTab, getInfoTab } from '/src/ui/core/tabs/get_tabs'
import useFetchSections from '/src/hooks/api/fetch_sections'
import ScopesTrackBlock from '/src/ui/domain/scopes/scopes_track_block'
import { CONTEXT_MENU_HIDDEN_STATUS } from '/src/utils/constants/scope'
import I18n from '/src/utils/translations'
import InspectedServicesBlock from '/src/ui/domain/inspections/inspected_services_block'
import { hasInspectedServices } from '/src/models/inspection'
import '/src/static/css/model_side_panel.css'

export default function ScopesSidePanel({
  dataItem,
  onClose,
  sections,
  contextMenuItems,
  template,
  inspection
}) {
  const [requestSections, loading] = useFetchSections(dataItem?.request?.eav_template_id, true)
  const showButtons = Boolean(contextMenuItems)
  const showContextMenu = !CONTEXT_MENU_HIDDEN_STATUS.includes(dataItem?.request?.request_status_id)

  const buildSummary = () => ({
    component: (
      <ScopesTrackBlock
        dataItem={dataItem}
        contextMenuItems={showContextMenu && contextMenuItems}
        template={template}
        showAttachments={showButtons}
      />
    ),
    title: '',
    type: 'track',
    key: 'scope-track-block'
  })

  const buildInspectedServices = () => {
    return {
      component: <InspectedServicesBlock inspection={inspection} />,
      title: I18n.t('inspections.inspected_services'),
      type: 'track',
      key: 'inspected-services-block',
      visible: hasInspectedServices(inspection)
    }
  }

  const getBlocks = (blocks) => {
    const defaultBlocks = [...blocks]
    if (inspection) defaultBlocks.push(buildInspectedServices())

    if (!dataItem) return null
    if (!dataItem.request_id) return defaultBlocks
    return [buildSummary(), ...defaultBlocks]
  }

  const initialSectionBlocks = useSectionBlock({ sections, dataItem, openModal: showButtons })
  const infoBlocks = getBlocks(initialSectionBlocks)

  const infoTab = getInfoTab(infoBlocks, showButtons ? 'info' : 'preview_details')

  const galleryModules = [
    { moduleName: 'request', sections: requestSections, dataItem: dataItem?.request },
    { moduleName: 'scope', sections, dataItem }
  ]

  const galleryTab = getGalleryTab({ modules: galleryModules, loading, showButtons })

  const scopesTabs = [infoTab, galleryTab]

  return (
    <div className="model-side-panel">
      <BlocksSidePanel
        title={I18n.t('scopes.scopes_detail.side_panel_title')}
        closable={showButtons}
        onClose={onClose}
      >
        <SidePanelTabs tabs={scopesTabs} />
      </BlocksSidePanel>
    </div>
  )
}

ScopesSidePanel.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  sections: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClose: PropTypes.func.isRequired,
  contextMenuItems: PropTypes.shape({
    edit: PropTypes.oneOfType([PropTypes.object]).isRequired,
    duplicate: PropTypes.oneOfType([PropTypes.object]).isRequired,
    remove: PropTypes.oneOfType([PropTypes.object]).isRequired
  }),
  template: PropTypes.oneOfType([PropTypes.object]),
  inspection: PropTypes.shape({
    inspected: PropTypes.shape({
      eav_template_id: PropTypes.number
    }),
    inspected_services: PropTypes.arrayOf(
      PropTypes.shape({
        service_number: PropTypes.number
      })
    ),
    inspected_type: PropTypes.string
  })
}

ScopesSidePanel.defaultProps = {
  dataItem: undefined,
  template: undefined,
  inspection: undefined
}

import React, { useState } from 'react'
import { HiPlusCircle } from 'react-icons/hi'
import { IoMdExit } from 'react-icons/io'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import I18n from '/src/utils/translations'
import { shouldShowSaveAndNewButton } from '/src/ui/core/forms/form_utils'
import ActionToolbar from '/src/ui/core/toolbar/action_toolbar'
import ActionButton from '/src/ui/core/buttons/action_button'

export default function FormButtons({ model, type, awaitFormSuccess, customDispatchEvent, extraButtons }) {
  const [nextClicked, setNextClicked] = useState(false)
  const [newClicked, setNewClicked] = useState(false)

  const showSaveAndNewButton = shouldShowSaveAndNewButton(model.paramName, type)
  const toolbarClass = classNames('form-buttons-toolbar', {
    'form-buttons-three-options': showSaveAndNewButton,
    'form-buttons': !showSaveAndNewButton
  })

  const handleClick = (createAnother = false) => {
    setNewClicked(createAnother)
    if (awaitFormSuccess) {
      dispatch(BusEvents.FORM_SUBMIT)
    } else {
      dispatch(BusEvents.FORM_SUBMITTED)
    }
  }

  useBus(
    BusEvents.STEPS_NEXT_BUTTON_CLICKED,
    () => {
      setNextClicked(true)
      handleClick()
    },
    [handleClick, setNextClicked]
  )

  useBus(
    BusEvents.FORM_SUBMITTED,
    () => {
      if (!nextClicked) dispatch({
        type: BusEvents.FORM_FINISHED,
        payload: { newClicked },
        customDispatchEvent
      })
    },
    [nextClicked, newClicked]
  )

  return (
    <ActionToolbar
      className={toolbarClass}
      cancelText={I18n.t('form.cancel')}
      onCancel={() => dispatch(BusEvents.FORM_DISCARD_CHANGES_CONFIRM)}
      actionText={I18n.t('actions.save_exit')}
      actionClassName={'button-action-save-exit'}
      actionIcon={<IoMdExit />}
      onAction={() => handleClick()}
    >
      {extraButtons?.map((button) => (
        <ActionButton
          key={button.id}
          title={button.title}
          className={button.className}
          icon={button.icon}
          onClick={() => button.onClick?.()}
        />
      ))}
      {shouldShowSaveAndNewButton(model.paramName, type) && (
        <ActionButton
          title={I18n.t('actions.save_new')}
          className={'button-action-save-new'}
          icon={<HiPlusCircle />}
          onClick={() => handleClick(true)}
        />
      )}
    </ActionToolbar>
  )
}

FormButtons.propTypes = {
  model: PropTypes.oneOfType([PropTypes.object]).isRequired,
  type: PropTypes.string,
  customDispatchEvent: PropTypes.string,
  awaitFormSuccess: PropTypes.bool,
  extraButtons: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    className: PropTypes.string,
    icon: PropTypes.element,
    onClick: PropTypes.func
  }))
}


FormButtons.defaultProps = {
  type: undefined,
  customDispatchEvent: undefined,
  awaitFormSuccess: false,
  extraButtons: undefined
}

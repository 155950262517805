import React, { useMemo, useState, useCallback } from 'react'
import { useStore, store } from 'react-context-hook'
import I18n from '/src/utils/translations'
import { isEmpty } from '/src/utils/object'
import ScaffoldingTypeFilterIcon from '/src/ui/core/icons/scaffolding_type_filter_icon'

export default function useScaffoldingTypeFilter() {
  const [scaffoldingTypes] = useStore('scaffolding_types')
  const [typesFilterIds] = useStore('scaffolding_type_filter')
  const [popupOpened, setPopupOpened] = useState(false)

  const typeFilterLabels = useMemo(() => {
    if (!scaffoldingTypes || isEmpty(scaffoldingTypes) || !typesFilterIds) return []

    return typesFilterIds.map(
      (id) =>
        scaffoldingTypes[id] &&
        I18n.t(`scaffolding_parts.scaffolding_types.${scaffoldingTypes[id].i18n_id}`)
    )
  }, [typesFilterIds, scaffoldingTypes])

  const clearTypeFilters = useCallback(() => {
    store.set('scaffolding_type_filter', [])
  }, [])

  const changeFilter = useCallback(
    (type) => {
      if (typesFilterIds.includes(type.id)) {
        store.set(
          'scaffolding_type_filter',
          typesFilterIds.filter((id) => id !== type.id)
        )
      } else {
        store.set('scaffolding_type_filter', [...typesFilterIds, type.id])
      }
    },
    [typesFilterIds]
  )

  const typeFilter = useMemo(
    () =>
      typesFilterIds &&
      typesFilterIds.length > 0 && {
        type: 'where',
        column: 'scaffolding_parts][scaffolding_type_id',
        operator: 'eq',
        value: typesFilterIds
      },
    [typesFilterIds]
  )

  const handlePopupToggle = useCallback(() => {
    setPopupOpened((prev) => !prev)
  }, [])

  const sortTypesByTranslation = (types) => {
    return Object.values(types).sort((a, b) => {
      const aTranslation = I18n.t(`scaffolding_parts.scaffolding_types.${a.i18n_id}`)
      const bTranslation = I18n.t(`scaffolding_parts.scaffolding_types.${b.i18n_id}`)
      return aTranslation.localeCompare(bTranslation)
    })
  }

  const typeFilterButton = (
    <ScaffoldingTypeFilterIcon
      types={scaffoldingTypes && sortTypesByTranslation(scaffoldingTypes)}
      typeDescription={(type) => I18n.t(`scaffolding_parts.scaffolding_types.${type.i18n_id}`)}
      isTypeSelected={(type) => typesFilterIds && typesFilterIds.includes(type.id)}
      onChangeFilter={changeFilter}
      onClearFilters={clearTypeFilters}
      popupOpened={popupOpened}
      setPopupOpened={handlePopupToggle}
    />
  )

  return {
    typeFilterButton,
    selectedTypes: typeFilterLabels,
    typeFilter,
    clearTypeFilters
  }
}

import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import lodash from 'lodash'
import { ComboBox, MultiSelect } from '@progress/kendo-react-dropdowns'
import { asString } from '/src/utils/string'
import useInputDrop from '/src/ui/core/inputs/input_drop_hook'
import useInputSearch from '/src/ui/core/inputs/input_search_hook'
import '/src/static/css/dropdown.css'

/**
 *  It will create a combobox using an array of objects as options
 *
 * @param inputProps
 * @return {*}
 * @constructor
 */
export default function InputSearch({ inputProps, prefix }) {
  const { opened, popupClassName, iconClassName, ref, appendTo, multiple, forceValue, value, clearButton } = inputProps

  const { wrapperClass, inputClass, error, onFocus, onBlur, onChange, readOnly } = useInputDrop({
    ...inputProps,
    options: [],
    onChange: () => {}
  })

  const { textToSearch, searchData, selectedValue, setSearchFilter, setComboValue, invalidValueError } = useInputSearch(
    inputProps,
    onChange
  )

  const [isOpened, setIsOpened] = useState()

  const onInputBlur = (e) => {
    if (onBlur) onBlur(e)
    setIsOpened(false)
  }

  const multiSelectData = useMemo(() => {
    const selectedValues = selectedValue || []

    if (selectedValues.length === 0 || ['string', 'number'].includes(typeof selectedValues[0])) return searchData

    const { merge, values, keyBy } = lodash

    const itemKey = inputProps.keyField || 'id'
    const key = searchData[itemKey] ? itemKey : 'computed_text_field'

    const merged = merge(keyBy(searchData, key), keyBy(selectedValues, key))
    return values(merged)
  }, [searchData, selectedValue, inputProps.keyField])

  const multiSelectPopupSettings = () => {
    const settings = {
      appendTo: document.querySelector(appendTo || 'body'),
      className: `combobox-list ${popupClassName || ''}`
    }

    if (inputProps.popupSettingsWidth) settings.width = inputProps.popupSettingsWidth

    return settings
  }

  const renderMultiple = () => {
    return (
      <MultiSelect
        id={inputProps.id}
        className={inputClass()}
        iconClassName={iconClassName}
        label={inputProps.label}
        filter={textToSearch}
        data={multiSelectData}
        value={searchData.length > 0 ? selectedValue : []}
        onChange={setComboValue}
        onFocus={onFocus}
        onBlur={onBlur}
        popupSettings={multiSelectPopupSettings()}
        filterable
        textField="computed_text_field"
        valueField="id"
        accessKey={inputProps.keyField || 'id'}
        dataItemKey={inputProps.keyField || 'id'}
        onFilterChange={setSearchFilter}
        clearButton={false}
        disabled={readOnly}
        placeholder={inputProps.placeholder}
        opened={opened}
        ref={ref}
      />
    )
  }

  const onInputClick = () => {
    if (readOnly) return
    setIsOpened((prevIsOpened) => !prevIsOpened)
  }

  const renderCombo = () => {
    const displayedValue = forceValue ? value : selectedValue
    const prefixedSearchData = prefix
      ? searchData.map((searchItem) => ({
          ...searchItem,
          computed_text_field: `${prefix}${searchItem.computed_text_field}`
        }))
      : searchData

    return (
      <div onClick={onInputClick}>
        <ComboBox
          id={inputProps.id}
          className={inputClass()}
          iconClassName={iconClassName}
          label={inputProps.label}
          data={prefixedSearchData}
          value={displayedValue}
          onChange={setComboValue}
          onFocus={onFocus}
          onBlur={onInputBlur}
          popupSettings={{
            appendTo: document.querySelector(appendTo || 'body'),
            className: `combobox-list ${popupClassName || ''}`
          }}
          filterable
          filter={asString(textToSearch)}
          textField="computed_text_field"
          valueField="id"
          onFilterChange={setSearchFilter}
          clearButton={clearButton || false}
          disabled={readOnly}
          placeholder={inputProps.placeholder}
          opened={opened || isOpened}
          ref={ref}
        />
      </div>
    )
  }

  return (
    <div className={wrapperClass}>
      {multiple ? renderMultiple() : renderCombo()}
      <div className="error-label">{error || invalidValueError}</div>
    </div>
  )
}

InputSearch.propTypes = {
  inputProps: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    className: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array, PropTypes.object]),
    onChange: PropTypes.func,
    dependency: PropTypes.string,
    searchExtraQuery: PropTypes.object,
    searchFields: PropTypes.arrayOf(PropTypes.string).isRequired,
    searchRoute: PropTypes.string.isRequired,
    label: PropTypes.string,
    textDisplayFields: PropTypes.arrayOf(PropTypes.string).isRequired,
    placeholder: PropTypes.string,
    appendTo: PropTypes.string,
    opened: PropTypes.bool,
    multiple: PropTypes.bool,
    popupClassName: PropTypes.string,
    iconClassName: PropTypes.string,
    ref: PropTypes.oneOfType([PropTypes.object]),
    keyField: PropTypes.string,
    forceValue: PropTypes.bool,
    clearButton: PropTypes.bool,
    popupSettingsWidth: PropTypes.string
  }).isRequired,
  prefix: PropTypes.string
}

InputSearch.defaultProps = {
  prefix: ''
}

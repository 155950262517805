import { useState, useEffect } from 'react'
import { useStore } from 'react-context-hook'
import { filterToQuery } from '/src/hooks/api/query'
import { isEmpty } from '/src/utils/object'

const SERVER_URL = import.meta.env.SNOWPACK_PUBLIC_DPMS_API_URL
const MAX_RECORDS = 1000

const exportQuery = (templateId, project, subproject, locale) => {
  const query = [`?eav_template_id=${templateId}`]

  if (project) query.push(`project_id=${project.id}`)
  if (subproject) query.push(`subproject_id=${subproject.id}`)
  query.push(`locale=${locale}`)

  return query.join('&')
}

/**
 * This hook will create excel export URL based on the entity name
 * @param entity - string, name of the entity
 * @param templateId - number/string, id of the template
 * @param isApi - boolean, if or not it is a API route
 * @returns {
 *   {
 *    linkExport: string created with the link to the excel export,
 *    setExportFilters: function to set filters to be included on the url
 *   }
 * }
 */
export default function useExportExcelUrl({ entity, templateId, isApi }) {
  const [exportFilters, setExportFilters] = useState()
  const [globalProject] = useStore('project')
  const [globalSubproject] = useStore('subproject')
  const [locale] = useStore('language')
  const apiUrl = isApi ? 'api/v1/' : ''
  const baseUrl = `${SERVER_URL}/${apiUrl}${entity}/export_xlsx.xlsx`

  const hasSelectedSubproject =
    globalProject && !isEmpty(globalProject) && globalSubproject && !isEmpty(globalSubproject)

  // eslint-disable-next-line max-len
  const queryString =
    hasSelectedSubproject && exportQuery(templateId, globalProject, globalSubproject, locale)
  const exportPath = baseUrl + queryString
  const [linkExport, setLinkExport] = useState(exportPath)

  useEffect(() => {
    let exportNew = exportPath

    if (exportFilters) {
      const { total, filters } = exportFilters

      if (total && total > MAX_RECORDS) exportNew = undefined
      else if (filters.length > 0) {
        const queriableFilters = filters.map((f) => filterToQuery(f)).join('&')
        exportNew += `&${queriableFilters}`
      }
    }

    setLinkExport(exportNew)
  }, [exportPath, exportFilters])

  return { linkExport, setExportFilters }
}

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { MdEdit } from 'react-icons/md'
import I18n from '/src/utils/translations'
import InspectedModule from '/src/models/concerns/inspected_module'
import InspectedModuleFilterStepsModal from '/src/ui/domain/inspections/popups/inspected_module_filter_steps_modal'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import '/src/static/css/core/inputs/input_filter.css'

export default function InputModuleFilter({ inputProps, extraParams }) {
  const { onChange } = inputProps
  const { columns } = new InspectedModule()
  const { eavTemplate } = extraParams

  const [open, setOpen] = useState(false)
  const [stepFilters, setStepFilters] = useState(columns)
  const [currentValues, setCurrentValues] = useState({})
  const [displayParentValues, setDisplayParentValues] = useState({})

  useEffect(() => {
    updateCurrentValues(stepFilters)
  }, [stepFilters])

  const updateCurrentValues = () => {
    let newCurrentValues = {}
    stepFilters.forEach((f) => {
      const val = f.value

      onChange(f.description, val)
      if (!val) return

      newCurrentValues = { ...newCurrentValues, [f.description]: val }
    })
    setCurrentValues(newCurrentValues)
  }

  const changeValues = () => {
    const typeMap = {
      'Progress': 'progress_services',
      'Scope': 'estimate_services'
    }

    if (currentValues.service_ids && typeMap[currentValues.inspected_type]) {
      currentValues.inspected_type = typeMap[currentValues.inspected_type]
    }
  }

  const onDone = (values, displayValues) => {
    const newStepFilters = stepFilters.map((f) => ({
      ...f,
      value: values[f.description],
      displayValue: displayValues[f.description]
    }))

    const inspectedType = newStepFilters[0].value
    const valueMapping = {
      'progress_services': 'Progress',
      'estimate_services': 'Scope'
    }

    if (valueMapping[inspectedType]) {
      newStepFilters[0].value = valueMapping[inspectedType]
    }

    dispatch({
      type: BusEvents.UPDATE_INSPECTED_ITEM,
      payload: values
    })

    setDisplayParentValues(displayValues)
    setStepFilters(newStepFilters)
    setOpen(false)
  }

  const renderValues = (value, description) => {
    if (Array.isArray(value) && value[0] === null) return I18n.t('fields.na')

    const formattedValue = Array.isArray(value)
      ? value.map((valueItem) => valueItem.computed_text_field || valueItem).join(', ')
      : value

    return description === 'inspected_type'
      ? I18n.t(`inspections.inspected_modules.${formattedValue.toLowerCase()}`)
      : formattedValue
  }

  const renderFilters = () => {
    const allFiltersEmpty = stepFilters.every((filter) => !filter.displayValue)

    if (allFiltersEmpty) {
      return stepFilters.map(({ id, title }) => (
        <div key={id}>{`${title}: ${I18n.t('fields.na')}`}</div>
      ))
    }

    return stepFilters
      .filter((filter) => filter.displayValue)
      .map(({ id, title, displayValue, description }) => {
        const renderedValue = renderValues(displayValue, description)
        return <div key={id}>{`${title}: ${renderedValue}`}</div>
      })
  }

  return (
    <React.Fragment>
      <div className='filter'>
        <div className='filter-header'>
          <div className='filter-group-title'>
            <div className='filter-title'>{I18n.t('inspections.module')}</div>
          </div>
          <div
            className='filter-edit'
            onClick={() => {
              changeValues()
              setOpen(true)
            }}
          >
            <MdEdit />
          </div>
        </div>
        <div className='filter-body'>
          <div className='filters'>{renderFilters()}</div>
        </div>
      </div>
      <div className='error-label' />
      {open && (
        <InspectedModuleFilterStepsModal
          filters={stepFilters}
          resultTitle={I18n.t('inspections.module')}
          onClose={() => setOpen(false)}
          currentValues={currentValues}
          displayParentValues={displayParentValues}
          onDone={onDone}
          eavTemplate={eavTemplate}
        />
      )}
    </React.Fragment>
  )
}

InputModuleFilter.propTypes = {
  inputProps: PropTypes.shape({
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired
      })
    )
  }).isRequired,
  extraParams: PropTypes.oneOfType([PropTypes.object])
}

InputModuleFilter.defaultProps = {
  extraParams: {}
}

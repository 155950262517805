import React from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import useModel from '/src/ui/core/forms/model_hook'
import ScaffoldingPartModel from '/src/models/scaffolding_part'
import FormButtons from '/src/ui/core/forms/form_buttons'
import FormWrapper from '/src/ui/core/forms/form_wrapper'
import Form from '/src/ui/core/forms/form'
import I18n from '/src/utils/translations'
import { TotalDimensionBadge } from '/src/ui/domain/scaffoldings/badges'
import { scaffoldingTotalUnit } from '/src/ui/domain/scaffoldings/scaffolding_part_total_column'
import '/src/static/css/domain/scaffoldings/scaffolding_part_form.css'

export default function ScaffoldingPartForm({ scaffolding, dataItem, opts }) {
  const scaffoldingParent = opts?.scaffolding ?? scaffolding
  const type = dataItem ? 'edit' : 'new'
  const submitParams = (type === 'new') ? { additionalResource: { path: 'create_with_actions' } } : {}
  const [globalSubproject] = useStore('subproject')
  const [scaffoldingPartModel] = useModel(new ScaffoldingPartModel())

  const processColumn = (column) => {
    if (type === 'edit' && column.description === 'scaffolding_type_id') {
      return { ...column, read_only: true }
    }
    return column
  }

  const includeOnForm = () => {
    return {
      subproject_id: globalSubproject.id,
      scaffolding_id: scaffoldingParent.id
    }
  }

  const badge = () => {
    if (type === 'new') return null
    return <TotalDimensionBadge total={dataItem.total} unit={scaffoldingTotalUnit(dataItem)} />
  }

  const defaultValues = {
    operation_erect: scaffoldingParent.operation_erect,
    operation_dismantle: scaffoldingParent.operation_dismantle
  }

  return (
    <FormWrapper
      model={scaffoldingPartModel}
      dataItem={dataItem || defaultValues}
      classes="scaffolding-part-form"
      type={type}
      badge={badge()}
      label={`#${scaffoldingParent.tag_number}`}
      backText={I18n.t('scaffolding_parts.back')}
      sidePanelSections={scaffoldingPartModel.sections}
      isModal
    >
      <React.Fragment>
        <Form
          model={scaffoldingPartModel}
          sections={[]}
          dataItem={dataItem || defaultValues}
          includeOnForm={includeOnForm()}
          type={type}
          processColumn={processColumn}
          submitParams={submitParams}
        />
        <FormButtons model={scaffoldingPartModel} awaitFormSuccess />
      </React.Fragment>
    </FormWrapper>
  )
}

ScaffoldingPartForm.propTypes = {
  scaffolding: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    tag_number: PropTypes.number,
    operation_dismantle: PropTypes.string,
    operation_erect: PropTypes.string
  }),
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  opts: PropTypes.shape({
    scaffolding: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      tag_number: PropTypes.number,
      operation_dismantle: PropTypes.string,
      operation_erect: PropTypes.string
    })
  })
}

ScaffoldingPartForm.defaultProps = {
  scaffolding: undefined,
  dataItem: undefined,
  opts: undefined
}
